import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { useAuth } from '../../contexts/AuthContext/AuthContext';

export default function DeveloperToken() {
  const [tokens, setTokens] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [tokenToDelete, setTokenToDelete] = useState(null);

  const [tenantOptions, setTenantOptions] = useState([]);
  const [dialogTenantOptions, setDialogTenantOptions] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newTokenDetails, setNewTokenDetails] = useState({
    tenantId: null,
    environment: '',
    region: '',
  });
  const [loading, setLoading] = useState(false);
  const [testingToken, setTestingToken] = useState(false);
  const [loadingTenants, setLoadingTenants] = useState(false);
  const [dialogLoadingTenants, setDialogLoadingTenants] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [dialogSearchQuery, setDialogSearchQuery] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const [isDeveloper, setIsDeveloper] = useState(undefined);

  const { token, getResourceURI, isRidecellAdmin, user } = useAuth();

  const fetchDeveloperTokenPermissions = async () => {
    const result = await fetch('/api/v1/docs/token-access', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${token}`
      },
    })
    if (result.status >= 200 && result.status < 300) {
      const data = await result.json();
      const isDeveloper = data.hasDeveloperTokenAccess && !isRidecellAdmin();
      if (isDeveloper) {
        fetchTokens(user.tenantId);
      }
      setIsDeveloper(isDeveloper);
    }
  }

  // Function to handle delete token
  const handleDeleteToken = async () => {
    if (!tokenToDelete) return;

    setLoading(true);

    try {
      await axios.delete(
        `/api/v1/tenants/${tokenToDelete.id}/`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );

      setSnackbar({ open: true, message: 'Token deleted successfully!', severity: 'success' });
      setTokens(tokens.filter((token) => token.id !== tokenToDelete.id));
      // setFilteredTokens(filteredTokens.filter((token) => !token.id.contains(tokenToDelete.id)));
      setIsDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error deleting token:', error);
      setSnackbar({ open: true, message: 'Failed to delete token', severity: 'error' });
    } finally {
      setLoading(false);
      setTokenToDelete(null);
    }
  };

  const fetchTenants = async (query = '', setOptions, setLoadingFlag) => {
    setLoadingFlag(true);
    try {
      const response = await axios.get(
        `/api/v1/tenants?search=${query}`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );

      const results = response.data.results;

      if (JSON.stringify(results) === '{}') {
        return;
      }
      const tenants = results.map((tenant) => ({
        id: tenant.id,
        name: tenant.general?.name || 'Unknown',
      }));

      setOptions(tenants);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to load tenants', severity: 'error' });
    } finally {
      setLoadingFlag(false);
    }
  };

  const fetchTokens = async (tenantId = '') => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/v1/developer-tokens${tenantId ? `/?search=${tenantId}` : ''}`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );
      const results = response.data.results;
      if (JSON.stringify(results) === '{}') {
        return;
      }
      setTokens(results);
    } catch (error) {
      console.error('Error fetching tokens:', error);
      setSnackbar({ open: true, message: 'Failed to load tokens', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setNewTokenDetails({ tenantId: null, environment: '', region: '' });
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCreateToken = async () => {
    const { tenantId, environment, region } = newTokenDetails;

    if (!tenantId || !environment || !region) {
      setSnackbar({ open: true, message: 'All fields are required', severity: 'warning' });
      return;
    }

    setLoading(true);

    try {
      await axios.post(
        `/api/v1/developer-tokens/`,
        {
          tenant_id: tenantId.id,
          developer_group: environment,
          region: region,
        },
        {
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSnackbar({ open: true, message: 'Developer token created successfully!', severity: 'success' });
      fetchTokens();
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Error creating developer token:', error);
      setSnackbar({ open: true, message: 'Failed to create developer token', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const truncateToken = (token) => {
    const start = token.slice(0, 6);
    const end = token.slice(-6);
    return `${start}...${end}`;
  };

  const handleCopyToken = (token) => {
    navigator.clipboard.writeText(token);
    setSnackbar({ open: true, message: 'Token copied to clipboard!', severity: 'success' });
  };

  const handleTestToken = async (developerToken) => {
    setTestingToken(true);
    try {
      const response = await axios.post(
        `/api/v1/health/validate-developer-token`,
        {
          'developer_token': `${developerToken}`,
          'test_url': `https://${getResourceURI()}/api/v3/services/settings`
        },
        {
          headers: {
            "Authorization": `JWT ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setSnackbar({ open: true, message: 'Token is valid!', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'Token is invalid!', severity: 'warning' });
      }
    } catch (error) {
      console.error('Error testing token:', error);
      setSnackbar({ open: true, message: 'Token test failed!', severity: 'error' });
    } finally {
      setTestingToken(false);
    }
  };

  const handleSearch = useCallback(
    debounce((query, setOptions, setLoadingFlag) => {
      fetchTenants(query, setOptions, setLoadingFlag);
    }, 300),
    []
  );

  // useEffect(() => {

  //   if (isDeveloper) return;

  //   if (searchQuery) {
  //     handleSearch(searchQuery, setTenantOptions, setLoadingTenants);
  //   } else {
  //     fetchTenants('', setTenantOptions, setLoadingTenants);
  //   }
  // }, [!isDeveloper, searchQuery]);

  useEffect(() => {
    if (dialogSearchQuery) {
      handleSearch(dialogSearchQuery, setDialogTenantOptions, setDialogLoadingTenants);
    } else {
      fetchTenants('', setDialogTenantOptions, setDialogLoadingTenants);
    }
  }, [searchQuery !== '', dialogSearchQuery !== '']);


  useEffect(() => {
    fetchDeveloperTokenPermissions();
  }, [isDeveloper === undefined]);


  return (
    <Box sx={{ padding: 3 }}>
      <Paper
        elevation={4}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: 3,
          borderRadius: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
          maxWidth: '900px',
          margin: '0 auto',
        }}
      >
        <Typography variant="h4" gutterBottom textAlign="center">
          Developer Tokens
        </Typography>
        {isDeveloper && <Typography variant="p" gutterBottom textAlign="center">
          Tokens that have been created for you will show up here, if any. Contact your Ridecell contact if you need a new token.
        </Typography>}
        {!isDeveloper && <Typography variant="p" gutterBottom textAlign="center">
          Select a tenant to view and manage developer tokens
        </Typography>}
        {!isDeveloper && <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <Autocomplete
              options={tenantOptions}
              getOptionLabel={(option) => `${option.name} (${option.id})`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Tenants"
                  variant="outlined"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingTenants ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              onChange={(event, value) => {
                setSelectedTenant(value);
                if (value?.id) {
                  fetchTokens(value.id);
                }
              }}
              value={selectedTenant}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} textAlign="right">
            <Button variant="contained" onClick={handleOpenDialog} fullWidth>
              Create New Token
            </Button>
          </Grid>
        </Grid>}

        <Box sx={{ marginTop: 3, maxHeight: '400px', overflow: 'auto' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <List>
              {tokens && tokens.map((token) => (
                <ListItem key={token.id} divider>
                  <Paper
                    elevation={2}
                    sx={{
                      padding: 2,
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6">
                        {token.developer_group?.charAt(0).toUpperCase() + token.developer_group?.slice(1)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Issuer: {token.iss}
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      Tenant: <strong>{token.tenant_id}</strong> | Region: <strong>{token.region}</strong>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Expiration: <strong>{new Date(token.exp).toLocaleString()}</strong>
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                      <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                        Token: {truncateToken(token.token)}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Copy to clipboard">
                          <IconButton onClick={() => handleCopyToken(token.token)} size="small">
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleTestToken(token.token)}
                          disabled={testingToken}
                          sx={{ ml: 1 }}
                        >
                          {testingToken ? <CircularProgress size={20} /> : 'Test Token'}
                        </Button>
                        <Tooltip title="Delete token">
                          <IconButton
                            onClick={() => {
                              setTokenToDelete(token);
                              setIsDeleteDialogOpen(true);
                            }}
                            size="small"
                            sx={{ ml: 1 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Paper>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Paper>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Delete Developer Token</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the token for <strong>{tokenToDelete?.environment}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteToken}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for creating new token */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Create New Developer Token</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={dialogTenantOptions}
            getOptionLabel={(option) => `${option.name} (${option.id})`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Tenant ID"
                variant="outlined"
                onChange={(e) => setDialogSearchQuery(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {dialogLoadingTenants ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={(event, value) => setNewTokenDetails({ ...newTokenDetails, tenantId: value })}
            value={newTokenDetails.tenantId}
            fullWidth
          />
          <TextField
            fullWidth
            margin="dense"
            label="Developer Group (e.g., owner)"
            variant="outlined"
            value={newTokenDetails.environment}
            onChange={(e) => setNewTokenDetails({ ...newTokenDetails, environment: e.target.value })}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Region (EU/US)"
            variant="outlined"
            value={newTokenDetails.region}
            onChange={(e) => setNewTokenDetails({ ...newTokenDetails, region: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button variant="contained" onClick={handleCreateToken} disabled={loading}>
            {loading ? <CircularProgress size={20} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
