import React, { useState, useEffect } from 'react'
import { Outlet, NavLink, useNavigate } from 'react-router-dom'

import {
    AppBar,
    Box,
    Container,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from '@mui/material'

import {
    Assistant as AssistantIcon,
    Menu as MenuIcon,
    Home as HomeIcon,
    AccountCircle as AccountCircleIcon,
    Recycling as RecyclingIcon,
    Api as ApiIcon,
    Article as ArticleIcon,
    Token as TokenIcon,
} from '@mui/icons-material'

import BannerImage from '../../assets/images/BannerLogo700X250_WHITE.png'
import LogoImage from '../../assets/images/Logo512.png'

import { useAuth } from '../../contexts/AuthContext/AuthContext'
/**
 * HomePage
 * 
 * This page should manage the header, footer and body content of the application.
 * The page should also own the navigation menu as well. 
 * We want a left side navigation menu, with the various supported high level apps as the menu items.
 * The header/navigation bar of the app will be persistent across all pages.
 * The footer the app will be persistent across all pages.
 *
 * @returns {JSX.Element}
 
 */

export default function Home() {

    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const { isRidecellAdmin, logout, token } = useAuth();

    // Setting the menu to be disabled by default for now
    const [isMenuEnabled, setIsMenuEnabled] = useState(false);

    const fetchDeveloperTokenPermissions = async () => {
        const result = await fetch('/api/v1/docs/token-access', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${token}`
            },
        })
        if (result.status >= 200 && result.status < 300) {
            const data = await result.json();
            setIsMenuEnabled(data.hasDeveloperTokenAccess);
        }
    }

    useEffect(() => {
        fetchDeveloperTokenPermissions();
    }, [!isMenuEnabled]);

    const renderMenu = () => {
        return (
            <Grid
                id='menu-content'
                role='presentation'
                onClick={() => { }}
                onKeyDown={() => { }}
            >
                <Drawer
                    anchor='left'
                    open={isMenuOpen}
                    onClose={() => setIsMenuOpen(false)}
                >
                    <Box id="menu-box" sx={{ minWidth: '300px' }}>
                        <List>
                            {
                                [
                                    { text: 'Docs', icon: <ArticleIcon />, route: '/' },
                                    { text: 'Developer Tokens', icon: <TokenIcon />, route: '/developer-tokens' },
                                ].map((item) => (
                                    <ListItemButton key={item.text}>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItem key={item.text}>
                                            <NavLink to={item.route} onClick={() => setIsMenuOpen(false)} className={({ isActive, isPending }) =>
                                                isActive
                                                    ? "active"
                                                    : isPending
                                                        ? "pending"
                                                        : ""
                                            }>
                                                <ListItemText primary={item.text} />
                                            </NavLink>
                                        </ListItem>
                                    </ListItemButton>
                                ))}
                        </List>
                    </Box>
                </Drawer>
            </Grid>
        )
    }

    return (
        <React.Fragment>
            <Grid container direction={'column'} spacing={1}>
                <Grid item xs={12}>
                    <AppBar position="static" sx={{ backgroundColor: '#54585A' }}>
                        <Toolbar>
                            {isMenuEnabled && <IconButton
                                id='menu-button'
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 1 }}
                                onClick={() => setIsMenuOpen(true)}
                            >
                                <MenuIcon />
                            </IconButton>}
                            {isMenuEnabled && renderMenu()}
                            <img src={BannerImage} alt="Ridecell Logo" style={{ height: '50px', width: 'auto' }} />
                            &nbsp;
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                <span>Developer Portal</span>
                            </Typography>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => setIsProfileMenuOpen(true)}
                                color="inherit"
                            >
                                <img src={LogoImage} alt="Ridecell Logo" style={{ height: '30px', width: 'auto' }} />
                            </IconButton>
                            <Menu // Profile Menu
                                id="menu-appbar"
                                anchorEl={document.getElementById('menu-appbar')}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={isProfileMenuOpen}
                                onClose={() => setIsProfileMenuOpen(false)}
                            >
                                <MenuItem onClick={() => {
                                    setIsProfileMenuOpen(false)
                                    logout()
                                    navigate('/')
                                }}>Logout</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <Container maxWidth={false}>
                    {/* 
                        This is how pages are rendered as children while using the react-router routes.
                        This will allow pages to be nested based on the route being rendered.
                     */}
                    <Outlet />
                </Container>
                <Grid container justifyContent="center" sx={{ marginTop: 2, padding: 3 }}>
                    <Typography variant="body2" textAlign="center">
                        All Rights Reserved <a href="https://www.ridecell.com/" target='_blank' rel="noreferrer">Ridecell</a> © 2024
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment >
    )

}