// import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from '../pages/Login/Login.jsx';
import ErrorPage from '../pages/Error/Error.jsx';
import Home from '../pages/Home/Home.jsx';
import ApiDoc from '../pages/Docs/Docs.jsx';
import DeveloperToken from '../pages/DeveloperTokens/DeveloperTokens.jsx';

import { useAuth } from '../contexts/AuthContext/AuthContext.jsx';


function AppRouter() {
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return (
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Login />} />
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path="/" element={<Home />} >
                <Route path="/developer-tokens" element={<DeveloperToken />} />
                <Route path="/" element={<ApiDoc />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    )

}

export default function RouterWrapper() {
    return (
        <Router>
            <AppRouter />
        </Router>
    )
}
