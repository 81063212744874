import React, { createContext, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { decodeJwt } from 'jose';


// Create the Auth context
const AuthContext = createContext();

// Custom hook to use the Auth context
export const useAuth = (options = {}) => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {

  const tokenDetailsStorageKey = '_t';
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(sessionStorage.getItem(tokenDetailsStorageKey));
  const [user, setUser] = useState(null);

  const isRidecellAdmin = () => {
    return user?.roles?.findIndex(role => role.name === 'RIDECELL_SUPERUSER') > -1;
  }

  // Returns a useable URI for the logged in user
  const getResourceURI = () => {
    const preFiltered = user?.resourceURI;
    if (!preFiltered) {
      return null;
    }
    const filtered = preFiltered?.split('https://').pop().split('/').shift();
    return filtered ?? 'global.us.uat.svc.ridecell.com';
  };

  const checkToken = async (token) => {
    try {
      const response = await Axios.get('/api/v1/health/validate-token', {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      const isValid = response.status === 200;
      if (!isValid) {
        return false;
      }
      const decodedToken = decodeJwt(token);
      const user = {
        email: decodedToken?.email,
        roles: decodedToken?.roles,
        tenantId: decodedToken?.tenant_id,
        resourceURI: decodedToken?.resource_uri,
        userId: decodedToken?.user_id,
      };
      setUser(user);
      return true
    } catch (error) {
      console.error('Token validation failed', error);
      return false;
    }
  }

  const login = async (token) => {
    const isValid = await checkToken(token);
    if (isValid) {
      setIsAuthenticated(true);
      setToken(token);
      sessionStorage.setItem(tokenDetailsStorageKey, token);
    } else {
      setIsAuthenticated(false);
      setToken(null);
      sessionStorage.removeItem(tokenDetailsStorageKey);
    }
  };

  const logout = () => {
    sessionStorage.removeItem(tokenDetailsStorageKey);
    setToken(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {

    async function processToken(token) {
      if (token && await checkToken(token)) {
        setIsAuthenticated(true);
        setToken(token);
        sessionStorage.setItem(tokenDetailsStorageKey, token);
      } else {
        setIsAuthenticated(false);
        setToken(null);
        sessionStorage.removeItem(tokenDetailsStorageKey);
      }
    }

    const currentToken = token;

    if (currentToken) {
      processToken(currentToken);
    }

  }, [token]);


  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      isRidecellAdmin,
      login,
      logout,
      getResourceURI,
      token,
      user
    }}>
      {children}
    </AuthContext.Provider>
  );
};
